/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const Notification = (props) => {
    const { onClose, handleAccept, content, title, isOpen, isCustom } = props;

    

    if (isCustom) {
        return (
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalHeader>
                    <p style={{ "fontWeight": "bold", "fontSize": "20px", "margin": "0", "padding": "0" }}>{title}</p>
                </ModalHeader>
                <ModalBody>
                    {content}
                </ModalBody>
                <ModalFooter className="center">
                    {onClose && <Button block color="primary" size="lg" onClick={onClose}>Cancelar</Button>}
                    {handleAccept && <Button block color="primary" size="lg" onClick={handleAccept}>Aceptar</Button>}
                </ModalFooter>
            </Modal>
        );
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalHeader>
                <p style={{ "fontWeight": "bold", "fontSize": "20px", "margin": "0", "padding": "0" }}>{title}</p>
            </ModalHeader>
            <ModalBody>
                <p style={{ "fontWeight": "normal", "fontSize": "18px", "margin": "0", "padding": "0" }} >{content}</p>
            </ModalBody>
            <ModalFooter className="center">
                {onClose && <Button   color="danger"  size="lg" onClick={onClose}>Cancelar</Button>}
                {handleAccept && <Button  color="primary" size="lg" onClick={handleAccept}>Aceptar</Button>}
            </ModalFooter>
        </Modal>
    );
};

export default Notification;
