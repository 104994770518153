import { TextField } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { Button } from 'reactstrap'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const CardMain = (props) => {

    const {
        onHandleCaptcha,
        onVerificateCustomer,
        handleChange,
        data,
        validity,
        captcha
    } = props

    const transformEvent = (name, myValue) => {
        const myDate = new Date(myValue);
        let result;
        try {
            result = myDate.toISOString();
        } catch (error) {
            result = new Date(result);
        }
        return {
            target: {
                name,
                value: result,
            },
        }
    }

    return (
        <div className="row">
            <div className="col-lg-12">
                < TextField
                    required
                    style={{ background: "white" }}
                    fullWidth
                    name="FirstName"
                    label="Nombre"
                    value={data.FirstName}
                    onChange={handleChange}
                    error={!validity.FirstName}
                    helperText={!validity.FirstName ? 'Ingrese su nombre' : ''}
                    variant="outlined"
                    className="mb-2"
                />
            </div>
            <div className="col-lg-6">
                < TextField
                    style={{ background: "white" }}
                    fullWidth
                    name="LastName"
                    label="Apellido Paterno"
                    value={data.LastName}
                    onChange={handleChange}
                    variant="outlined"
                    className="mb-2"
                />
            </div>
            <div className="col-lg-6">
                < TextField
                    style={{ background: "white" }}
                    fullWidth
                    name="LastName2"
                    label="Apellido Materno"
                    value={data.LastName2}
                    onChange={handleChange}
                    variant="outlined"
                    className="mb-2"
                />
            </div>
            <div className="col-lg-6">
                < TextField
                    required
                    style={{ background: "white" }}
                    fullWidth
                    className="mb-2"
                    name="Phone"
                    label="Teléfono"
                    onChange={handleChange}
                    value={data.Phone}
                    variant="outlined"
                    type='tel'
                    error={!validity.Phone}
                    helperText={!validity.Phone ? 'Teléfono inválido !' : ''}
                    inputProps={{ minLength: 0, maxLength: 10, }}
                />
            </div>
            <div className="col-lg-6">
                < TextField
                    style={{ background: "white" }}
                    fullWidth
                    className="mb-2"
                    name="Email"
                    label="Correo electrónico"
                    variant="outlined"
                    value={data.Email}
                    onChange={handleChange}
                    error={!validity.Email}
                    helperText={!validity.Email ? 'Correo electrónico inválido !' : ''}
                    inputProps={{ inputMode: 'email' }}
                />
            </div>
            <div className="col-lg-6">
                < TextField
                    fullWidth
                    className="mb-2"
                    name="Number"
                    style={{ background: "white" }}
                    label="Código Postal"
                    variant="outlined"
                    value={data.Number}
                    onChange={handleChange}
                    error={!validity.Number}
                    helperText={!validity.Number ? 'Código Postal inválido !' : ''}
                    inputProps={{ minLength: 0, maxLength: 6, }}
                />
            </div>
            <div className="col-lg-6">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        disableFuture
                        openTo="year"
                        views={['year', 'month', 'day']}
                        label="Fecha de nacimiento"
                        value={data.BirthDate}
                        onChange={(newValue) => {
                            handleChange(transformEvent('BirthDate', newValue));
                        }}
                        renderInput={(params) => <> <TextField style={{ background: "white" }} {...params} error={false} fullWidth className="mb-2" /></>}
                    />
                </LocalizationProvider>
            </div>
            <div className="col-lg-12">
                <ReCAPTCHA
                    ref={captcha}
                    sitekey="6LdIlkMjAAAAABB8ocZ60WThidJnDVrZsIdTvgCy"
                    onChange={onHandleCaptcha}
                />,
            </div>
            <div className="col-lg-12">
                <Button block color="primary" size="lg" onClick={() => { onVerificateCustomer() }}>Registrar</Button>
            </div>



        </div >
    )
}

export default CardMain