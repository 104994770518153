import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { restApiVersion, useQueryParams } from '../../utils/Generals';
import Loader from '../../components/Loader';
import Page404 from '../Page404/Page404';
import Company from '../Company/Company'

const Search = () => {

  const queryParams = useQueryParams();
  const param_company = queryParams.get("company_id")
  const [dataLoaded, setDataLoaded] = useState(true);
  const [company, setCompany] = useState(null);

  useEffect(() => {

    if (!param_company)
      return setDataLoaded(false)

    axios.get(`${restApiVersion}/sale/cfdi/companies?ID=${param_company}`)
      .then(response => {

        setCompany(response.data)

        if (response.data.length > 0) {
          localStorage.setItem('access_token_portal', response.data[0].AccessToken);
        }


        setDataLoaded(false)
      });
  }, [])

  if (dataLoaded)
    return <Loader processing={dataLoaded} />

  if (company === null) {
    return <Page404 />
  } else if (company.length === 0) {
    return <Page404 />
  } else {
    return <Company company={company[0]} />
  }
}

export default Search