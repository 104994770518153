import { Grid } from '@mui/material';

import React from 'react'
import { ReactComponent as SvgArt00 } from '../../assets/images/Error404.svg';


const Page404 = () => {
  return (


    <Grid container direction="column" justifyContent="center" alignItems="center" marginTop={10} textAlign="center">
      <Grid item>
        <h1> Lo sentimos, página no encontrada!</h1>
      </Grid>

      <SvgArt00 style={{ maxWidth: "500px" }} />
      <Grid  >
        <p>Lo sentimos, no pudimos encontrar la página que estás buscando. <br /> ¿Quizás has escrito mal la URL? <br /><br /> Asegúrese de revisar su URL.</p>
      </Grid>
    </Grid>



  )
}

export default Page404