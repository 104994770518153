export const restApiVersion = "/rest/api/v4"


export const dataClean = {
    Phone: '',
    Email: '',
    FirstName: '',
    LastName: '',
    LastName2: '',
    Number: '',
    BirthDate: null
}

export const validityClean = {
    Phone: true,
    Email: true,
    FirstName: true,
    Number: true
}

export const CustomerPayload = {
    Name: '',
    TradeName: '',
    TaxCodeTypeID: 0,
    TaxCode: '',
    AddressID: 0,
    AddressUUID: 0,
    Discount: 0,
    PriceListID: 0,
    PersonID: 0,
    PersonUUID: 0,
    ContactPersonID: 0,
    FixedCategory101ID: 0,
    FixedCategory102ID: 0,
    FixedCategory103ID: 0,
    FixedCategory104ID: 0,
    FixedCategory105ID: 0,
    FixedCategory106ID: 0,
    FixedCategory107ID: 0,
    FixedCategory108ID: 0,
    FixedCategory109ID: 0,
    FixedCategory110ID: 0,
    VirtualWalletID: 0,
    VirtualWlletAdvanceID: 0,
    VirtualWalletGiftID: 0,
    POSReadOnly: 0,
    NotApplyTax1: 0,
    NotApplyTax2: 0,
    NotApplyTax3: 0,
    IsCounter: 0,
    IsSybsidiary: 0,
    AcceptCheck: 0,
    AcceptCredit: 0,
    CreditLimit: 0,
    CreditCharges: 0,
    CreditPayments: 0,
    CreditOwed: 0,
    CreditDueDays: 0,
    CreditBackReference: '',
    CreditType: 0,
    UseBonus: 0,
    Bonus: 0,
    Custom01: '',
    Custom02: '',
    Custom03: '',
    Custom04: '',
    Custom05: '',
    Custom06: '',
    Custom07: '',
    Custom08: '',
    Custom09: '',
    Custom10: '',
    CustomerStatusID: 0,
    Comment: '',
    RequestCRMInsert: 0,
    RequestERPInsert: 0,
    ErpSendStatusID: 1,
    MonthlyCashPayments: 0,
    IsCashExceededStatus: 0
}

export const AddressPayload = {
    VoidUserID: 0,
    Street: '',
    ExtNo: '',
    IntNo: '',
    ZipCodeID: 0,
    ZipCodeUUID: 0,
    Zip: '',
    Email1: '',
    Email2: '',
    Phone1: '',
    Phone2: '',
    Phone3: '',
    Latitute: 0,
    Longitude: 0,
}

export const PersonPayload = {
    FirstName: '',
    LastName: '',
    LastName2: '',
    Name: '',
    BirthDate: '',
    Gender: '',
    IdentificactionDocument: '',
    IdendificationDocumentName: '',
    Passport: '',
    Email: '',
    Email2: '',
    Phone1: '',
    Phone2: '',
    Phone3: '',
    FixedCategory301ID: 0,
    FixedCategory302ID: 0,
    FixedCategory303ID: 0,
    FixedCategory304ID: 0,
    FixedCategory305ID: 0,
    Custom01: ''
}