import { Backdrop, CircularProgress } from '@mui/material'
import React from 'react'

const Loader = (props) => {

    const {
        processing
    } = props


    return (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={processing}>
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}

export default Loader