import { Route, Routes } from "react-router-dom";
import Page404 from "./views/Page404/Page404";
import Search from "./views/Search/Search"


function App() {
  return (
    <>
        <Routes>
          <Route path="/" element={<Search />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
    </>
  );
}

export default App;
