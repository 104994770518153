import axios from "axios";
import { restApiVersion } from "./DataDefault";

async function GetExistCustomer(payload) {
    try {

        const headers = { headers: { Authorization: `Bearer ${localStorage.access_token_portal}` } };
        const dataResponse = await axios.post(`${restApiVersion}/customers/exists`, payload, headers);
        const myData = await dataResponse.data;
        return myData;

    } catch (error) {
        return Promise.reject(error);
    }

}

async function GetZipCode(payload) {

    try {

        const headers = { headers: { Authorization: `Bearer ${localStorage.access_token_register}` } };
        const dataResponse = await axios.get(`${restApiVersion}/addresses/zipcodes?Number=${payload}&mask_fields=ID,UUID,Number`, headers);
        const myData = await dataResponse.data;
        return myData;

    } catch (error) {
        return Promise.reject(error);
    }
}

async function PostData(myURL, myData) {
    try {
        const headers = { headers: { Authorization: `Bearer ${localStorage.access_token_register}` } };
        const dataResponse = await axios.post(encodeURI(myURL), myData, headers);
        const finalData = await dataResponse.data;
        return finalData;
    } catch (err) {
        return Promise.reject(err);
    }
};

export { GetExistCustomer, GetZipCode, PostData };
