import Terminos_4 from '../assets/pdf/bachoco/terminos.pdf'
import Privacidad_4 from '../assets/pdf/bachoco//APrivacidad.pdf'
import bg_company from '../assets/images/customers/bachoco/background_company4.jpg'
import bg_text from '../assets/images/customers/bachoco/background_text.jpg'

/** background default */
import bg_default from '../assets/images/default/background.svg'
import bg_text_default from '../assets/images/default/background_text.jpg'


export const CustomerInformation = [
    {
        ID: 4,
        bg_company: bg_default,
        bg_text: bg_text_default,
        text_header: "PLAN DE LEALTAD",
        text_information: "Inscríbete a nuestro programa de LEALTAD registrando tus datos en esta página",
        text_information2: "Obtén promociones y descuentos exclusivos en nuestras sucursales",
        text_politic: "*Vigencia del 01 de Agosto del 2019 a Diciembre 2022. Consulta términos y condiciones. Todas las compras realizadas con descuentos Plan de Lealtad no son facturables, ya que es una promoción dirigida a cliente final.",
        URL_termsAndConditions: Terminos_4,
        URL_privacy: Privacidad_4,
        email: "contactoryc@bachoco.net",
        location: "Prolongación 27 norte 10262, Parque industrial 5 de mayo. CP 72019. Puebla, Pue. México"
    },
    {
        ID: 9,
        bg_company: bg_company,
        bg_text: bg_text,
        text_header: "PLAN DE LEALTAD",
        text_information: "Inscríbete a nuestro programa de LEALTAD registrando tus datos en esta página",
        text_information2: "Obtén promociones y descuentos exclusivos en nuestras sucursales",
        text_politic: "*Vigencia del 01 de Agosto del 2019 a Diciembre 2022. Consulta términos y condiciones. Todas las compras realizadas con descuentos Plan de Lealtad no son facturables, ya que es una promoción dirigida a cliente final.",
        URL_termsAndConditions: Terminos_4,
        URL_privacy: Privacidad_4,
        email: "contactoryc@bachoco.net",
        location: "Prolongación 27 norte 10262, Parque industrial 5 de mayo. CP 72019. Puebla, Pue. México"
    },
    {
        ID: 10,
        bg_company: bg_default,
        bg_text: bg_text_default,
        text_header: "PLAN DE LEALTAD",
        text_information: "Inscríbete a nuestro programa de LEALTAD registrando tus datos en esta página",
        text_information2: "Obtén promociones y descuentos exclusivos en nuestras sucursales",
        text_politic: "*Vigencia del 01 de Agosto del 2019 a Diciembre 2022. Consulta términos y condiciones. Todas las compras realizadas con descuentos Plan de Lealtad no son facturables, ya que es una promoción dirigida a cliente final.",
        URL_termsAndConditions: Terminos_4,
        URL_privacy: Privacidad_4,
        email: "contactoryc@bachoco.net",
        location: "Prolongación 27 norte 10262, Parque industrial 5 de mayo. CP 72019. Puebla, Pue. México"
    }
]