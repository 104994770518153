import { Link } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import CardMain from './CardMain';
import { AddressPayload, CustomerPayload, dataClean, PersonPayload, restApiVersion, validityClean } from '../../utils/DataDefault';
import { GetExistCustomer, GetZipCode, PostData } from '../../utils/DataPromise';
import Loader from '../../components/Loader';
import Notification from '../../components/Modal';
import { CustomerInformation } from '../../utils/CustomerInformation';
import poweredBy from '../../assets/images/logo_sbx/powered.png'
import Page404 from '../Page404/Page404';

const Company = (props) => {

  const {
    company
  } = props

  const captcha = useRef(null)
  const urlImg = `/rest/api/v4/system/general/image?target=Company&id=${company.ID}&max_age=3600&access_token=${company.AccessToken}`;
  const [dataClient, setDataClient] = useState({ ID: 0 });
  const [dataLoaded, setDataLoaded] = useState(true);
  const [data, setData] = useState(dataClean);
  const [validity, setValidity] = useState(validityClean);
  const [captchaValidate, setCaptchaValidate] = useState(null);
  const [processing, setProcessing] = useState(false);

  const [showGoDuplicatedPhone, setShowGoDuplicatedPhone] = useState(false);
  const [showGoErrors, setShowGoErrors] = useState({ value: false, title: '', message: '' });

  const cleanState = () => {
    setData(dataClean)
    setValidity(validityClean)
    setCaptchaValidate(null)
  }

  const handleChange = (e) => {
    const { name, value } = e.target

    if (name === "Phone") {
      setData({ ...data, Phone: value })
      const pattern = "[0-9]{10}"
      setValidity({ ...validity, Phone: value === '' || value.match(pattern) ? true : false });
    } else if (name === 'Email') {
      setData({ ...data, Email: value })
      const pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      setValidity({ ...validity, Email: value === '' || value.match(pattern) ? true : false });
    } else if (name === 'FirstName') {
      setData({ ...data, FirstName: value })
      setValidity({ ...validity, FirstName: value === '' ? false : true });
    } else if (name === 'LastName') {
      setData({ ...data, LastName: value })
    } else if (name === 'LastName2') {
      setData({ ...data, LastName2: value })
    } else if (name === 'Number') {
      setData({ ...data, Number: value })
      const pattern = "[0-9]{5}"
      setValidity({ ...validity, Number: value === '' || value.match(pattern) ? true : false });
    } else if (name === 'BirthDate') {
      setData({ ...data, BirthDate: value })
    }


  }

  const postCustomer = () => {

    let Name = data.FirstName;
    if (data.LastName) Name += ` ${data.LastName}`;
    if (data.LastName2) Name += ` ${data.LastName2}`;

    const person_payload = { ...PersonPayload, FirstName: data.FirstName, LastName: data.LastName, LastName2: data.LastName2, Name: Name, Phone1: data.Phone, Email: data.Email, BirthDate: data.BirthDate }
    const address_payload = { ...AddressPayload, Email1: data.Email, Phone1: data.Phone, Zip: data.ZipCode }
    const customer_payload = { ...CustomerPayload, Name: Name }


    const saveCustomer = () => {


      PostData(`${restApiVersion}/customers/customers`, customer_payload)
        .then(response => {
          setShowGoErrors({ ...showGoErrors, value: true, title: "Registro Exitoso", message: "Se ha registrado correctamente. en sus proximas compras mencione su nombre y/o numero telefonico al cajero." })
          setProcessing(false)
          cleanState()
        }).catch(error => {
          setShowGoErrors({ ...showGoErrors, value: true, title: "Error", message: error.response.data })
          setProcessing(false)
        })
    }

    const createVirtualWallet = () => {
      console.log("entro aqui")

      PostData(`${restApiVersion}/sale/virtualwallets`, {})
        .then(response => {
          console.log(response)
          customer_payload.VirtualWalletID = response.ID

          saveCustomer()

        }).catch(error => {
          if (error.response.data.error.includes("Folio")) {
            saveCustomer()
          }
        }).catch(error => {
          setShowGoErrors({ ...showGoErrors, value: true, title: "Error", message: error.response.data })
          setProcessing(false)
        })

    }

    const saveInformation = () => {

      Promise.all([
        PostData(`${restApiVersion}/addresses/addresses`, address_payload),
        PostData(`${restApiVersion}/system/general/persons`, person_payload)
      ]).then(response => {

        const myAddressResponse = response[0]
        const myPersonReponse = response[1]

        customer_payload.PersonID = myPersonReponse.ID
        customer_payload.PersonUUID = myPersonReponse.UUID
        customer_payload.AddressID = myAddressResponse.ID
        customer_payload.AddressUUID = myAddressResponse.UUID

        createVirtualWallet()
      }).catch(error => {
        setShowGoErrors({ ...showGoErrors, value: true, title: "Error", message: error.response.data })
        setProcessing(false)
      })

    }


    if (!data.Number.length > 0) {
      saveInformation();
    }

    GetZipCode(data.Number)
      .then(response => {
        if (response.elements.length === 0) {


          PostData(`${restApiVersion}/addresses/zipcodes`, { Number: data.Number, Fixed: 0 })
            .then(postZipCode => {
              address_payload.ZipCodeID = postZipCode.ID;
              address_payload.ZipCodeUUID = postZipCode.UUID;
              // 2. Se persiste los datos del domicilio
              saveInformation();
            })
            .catch(error => {
              if (error.response.data.error.includes("Token")) {
                setShowGoErrors({ ...showGoErrors, value: true, title: "Error", message: error.response.data })
                setProcessing(false)
              }
            });

        } else {
          address_payload.ZipCodeID = response.elements[0].ID;
          address_payload.ZipCodeUUID = response.elements[0].UUID;
          // 2. Se persiste los datos del domicilio
          saveInformation();
        }


      }).catch(error => {
        setShowGoErrors({ ...showGoErrors, value: true, title: "Error", message: error.response.data })
        setProcessing(false)
      })

  }

  const onVerificateCustomer = () => {

    if (!data.FirstName.length > 0) {
      setShowGoErrors({ ...showGoErrors, value: true, title: "Nombre", message: "Favor de  ingresar tu nombre" })
      setValidity({ ...validity, FirstName: false })
      return
    }

    if (!data.Phone.length > 0) {
      setShowGoErrors({ ...showGoErrors, value: true, title: "Teléfono", message: "Favor de ingresar tu numero telefonico" })
      setValidity({ ...validity, Phone: false })
      return
    }

    if (!validity.Phone) {
      setShowGoErrors({ ...showGoErrors, value: true, title: "Teléfono", message: "favor de introducir correctamente el numero telefonico" })
      return
    }

    if (!validity.Email) {
      setShowGoErrors({ ...showGoErrors, value: true, title: "Teléfono", message: "favor de introducir correctamente el correo electronico" })
      return
    }


    if (!captchaValidate) {
      setShowGoErrors({ ...showGoErrors, value: true, title: "Autenticación", message: "favor de realizar la autenticación" })
      return
    }



    const payload = { "phone": data.Phone }
    setProcessing(true)
    GetExistCustomer(payload)
      .then(response => {



        localStorage.setItem("access_token_register", response.access_token)

        if (response.customer_id > 0) {
          setValidity({ ...validity, Phone: false })
          setShowGoDuplicatedPhone(true)
          setProcessing(false)
          return
        }

        postCustomer()

      }).catch(error => {

        if (error.response.data.error.includes("Access denied")) {

          setShowGoErrors({ ...showGoErrors, value: true, title: "Acceso no autorizado", message: "Servicio no disponible." })
          setProcessing(false)
        } else {
          setShowGoErrors({ ...showGoErrors, value: true, title: "Error", message: error.response.data })
        }

      })



  }

  const onHandleCaptcha = () => {
    setCaptchaValidate(captcha.current.getValue())
  }


  useEffect(() => {

    const customer = CustomerInformation.find(item => item.ID === company.ID)

    if (customer) {
      setDataClient(customer)
      setDataLoaded(false)
    } else {
      setDataLoaded(false)
    }


  }, [company])

  if (dataLoaded)
    return <Loader processing={dataLoaded} />

  if (!dataLoaded && dataClient.ID === 0) {
    return <Page404 />
  }

  return (

    <div className="limiter">
      <div className="container-portal100">
        <div className="wrap-portal100">

          <div className="portal100-more " style={{ backgroundImage: `url('${dataClient.bg_company}')` }} >
            <div className=" d-none d-md-flex col-md-5 col-lg-8 align-items-end" >
              <div className="hero-container">
                <h1>{dataClient.text_header}</h1>
                <h2>{dataClient.text_information}
                  <br />
                  {dataClient.text_information2}
                </h2>
                <h4>
                  {dataClient.text_politic}
                </h4>
                <br />
                <h4 style={{ color: "white", fontWeight: "bold" }}>
                  {dataClient.URL_termsAndConditions && <Link href={dataClient.URL_termsAndConditions} color="inherit" target="_blank" rel="noopener" style={{ marginRight: "50px" }} >TÉRMINOS Y CONDICIONES</Link>}
                  {dataClient.URL_privacy && <Link href={dataClient.URL_privacy} color="inherit" target="_blank" rel="noopener" >POLÍTICA DE PRIVACIDAD</Link>}
                </h4>
              </div>

            </div>
          </div>

          <div className="portal100-form" style={{ backgroundImage: `url('${dataClient.bg_text}')` }}>
            <div className="text-center mb-5" style={{marginTop: 20}}  >
              <img className='' src={urlImg} alt="" style={{ maxWidth: '300px', objectFit: 'contain', filter: "brightness(1.1)", mixBlendMode: "multiply" }} />
            </div>

            <CardMain
              onHandleCaptcha={onHandleCaptcha}
              handleChange={handleChange}
              data={data}
              validity={validity}
              onVerificateCustomer={onVerificateCustomer}
              captcha={captcha}
            />

            <div className="col-lg-12" style={{ marginTop: "30px" }}>
              <p className='directions' style={{ fontSize: "16px", }}>
                <b><i className="bi bi-envelope-fill" style={{ marginRight: "10px" }}></i></b><Link href={`mailto:${dataClient.email}`} underline="hover" color={"black"}>{dataClient.email}</Link><br />
                <b><i className="bi bi-geo-alt-fill" style={{ marginRight: "10px" }}></i></b>{dataClient.location}</p>
            </div>
            <div className="text-center directions" >
              <Link href="https://www.sbxretail.com/" color="inherit" target="_blank" rel="noopener" ><img className='' src={poweredBy} alt="SBX_Retail" style={{ maxWidth: '250px', objectFit: 'contain', filter: "brightness(1.1)", mixBlendMode: "multiply", marginTop: 30 }} /></Link>
            </div>
          </div>
        </div>
      </div>
      <Loader processing={processing} />
      <Notification
        isOpen={showGoDuplicatedPhone}

        handleAccept={() => {
          setShowGoDuplicatedPhone(false)
        }}
        title="Número Duplicado"
        content="Ya existe un cliente con el número telefonico que ingreso, favor de ingresar otro"
      />
      <Notification
        isOpen={showGoErrors.value}

        handleAccept={() => {
          setShowGoErrors({ value: false, title: '', message: '' })
        }}
        title={showGoErrors.title}
        content={showGoErrors.message}
      />
    </div>

  )
}

export default Company